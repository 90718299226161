import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import { useEnterprises } from '../contexts/EnterpriseContext';
import { useStations } from '../contexts/StationsContext';
import FirstAccess from '../pages/FirstAccess';
import ForgotPassword from '../pages/ForgetPassword';
import Dashboard from '../pages/Dashboard';
import Signin from '../pages/Signin';
import Signup from '../pages/Signup';
import Station from '../pages/Station';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

interface PublicRouteProps {
  children: React.ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { isAuthenticated, authLoading } = useAuth();
  const { currentEnterprise, enterpriseLoading } = useEnterprises();
  const { stations, stationsLoading } = useStations();

  if (isAuthenticated) {
    if (!enterpriseLoading && !authLoading) {
      if (currentEnterprise?.enterpriseId) {
        if (!stationsLoading) {
          if (stations && stations.length > 0) {
            return <Navigate to="/dashboard" replace />;
          } else {
            return <Navigate to="/primeiro-acesso" replace />;
          }
        }
      } else {
        return <Navigate to="/primeiro-acesso" replace />;
      }
    }
  }

  return <>{children}</>;
};

const publicRoutes = [
  { path: '/', Component: Signin },
  { path: '/esqueci-a-senha', Component: ForgotPassword },
  { path: '/cadastrar', Component: Signup },
];

const privateRoutes = [
  { path: '/primeiro-acesso', Component: FirstAccess },
  { path: '/dashboard', Component: Dashboard },
  { path: '/usina/:id', Component: Station },
];

function AppRoutes() {
  return (
    <Router>
      <Routes>
        {publicRoutes.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={
              <PublicRoute>
                <Component />
              </PublicRoute>
            }
          />
        ))}

        {privateRoutes.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={
              <PrivateRoute>
                <Component />
              </PrivateRoute>
            }
          />
        ))}
      </Routes>
    </Router>
  );
}

export default AppRoutes;
