import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { RxPencil2 } from 'react-icons/rx';
import { cn } from '../lib/utils';
import { Button, buttonVariants } from '../components/ui/button';
import { Label } from '../components/ui/label';
import { Input } from '../components/ui/input';
import { Icons } from '../components/icons';
// import signInEmail from '../firebase/auth/singnin-email';
import { useAuth } from '../contexts/AuthContext';
import signInEmail from '../firebase/auth/singnin-email';
import { useToast } from '../components/ui/use-toast';
import { useStations } from '../contexts/StationsContext';

export default function Signin() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const { signInContext } = useAuth();
  const { stationsLoading } = useStations();

  async function forgotPassword() {
    navigate('/esqueci-a-senha');
  }

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await signInContext({ email, password });
      if (!response) {
        setIsLoading(false);
        return toast({
          variant: 'error',
          duration: 3000,
          title: 'Não foi possível acessar os dados.',
        });
      }
      return toast({
        variant: 'success',
        duration: 3000,
        title: 'Login efetuado com sucesso.',
      });
    } catch (error: any) {
      setIsLoading(false);
      return toast({
        variant: 'error',
        duration: 3000,
        title: error.message || 'Não foi possível acessar os dados.',
      });
    }
  }

  useEffect(() => {
    if (isLoading && !stationsLoading) {
      setIsLoading(false);
    }
  }, [stationsLoading]);

  return (
    <div className="flex justify-between bg-backgroundLogin h-screen">
      <div
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0.70) 15.05%, rgba(0, 0, 0, 0.00) 116.72%), url('fundologin.png') right bottom / cover no-repeat, lightgray 50%`,
        }}
        className="w-1/2 hidden lg:flex flex-col justify-between h-screen"
      >
        <div className="p-10">
          <img src="logo-sweg.svg" width={106} height={36} alt="Sweg logo" />
        </div>
      </div>
      <div className="w-full md:w-1/2 p-4 flex-col">
        <div className="flex items-center justify-end mr-4">
          <p className="text-sm text-textColorSidebar mx-4">
            Ainda não tem conta?
          </p>
          <Link
            to="/cadastrar"
            className={cn(buttonVariants({ variant: 'default' }))}
          >
            <RxPencil2 className="mr-2 h-4 w-4" />
            Cadastre-se
          </Link>
        </div>
        <div className="flex justify-center items-center h-[calc(100%-40px)]">
          <div className=" flex flex-col justify-center space-y-8 w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight text-white">
                Bem-vindo à Sweg
              </h1>
            </div>
            <div>
              <div className="relative my-4">
                <div className="absolute inset-0 flex items-center ">
                  <span className="w-full border-t border-border" />
                </div>
                <div className="relative flex justify-center text-xs uppercase ">
                  <span className="bg-backgroundLogin px-2 text-muted-foreground">
                    OU ENTRAR COM EMAIL E SENHA
                  </span>
                </div>
              </div>
              <form onSubmit={onSubmit}>
                <div className="grid ">
                  <div className="grid w-full max-w-sm items-center mb-6">
                    <Label
                      className="text-muted-foreground mb-2"
                      htmlFor="email"
                    >
                      Email
                    </Label>

                    <Input
                      id="email"
                      placeholder="name@example.com"
                      type="email"
                      autoCapitalize="none"
                      autoComplete="email"
                      autoCorrect="off"
                      disabled={isLoading}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="grid w-full max-w-sm items-center  mb-6">
                    <Label
                      className="text-muted-foreground mb-2"
                      htmlFor="password"
                    >
                      Senha
                    </Label>

                    <Input
                      id="password"
                      placeholder="password"
                      type="password"
                      autoCapitalize="none"
                      autoComplete="password"
                      autoCorrect="off"
                      disabled={isLoading}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <Button variant="default" disabled={isLoading}>
                    {isLoading && (
                      <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Entrar
                  </Button>
                  <Button
                    type="button"
                    onClick={forgotPassword}
                    className="text-sweg-secondary mt-4"
                    variant="link"
                  >
                    Esqueci minha senha
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
