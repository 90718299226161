import { getAuth, signOut } from 'firebase/auth';
import firebase_app from '../config';
import { key } from '../../config/key';
const auth = getAuth(firebase_app);

export default async function signOutUser() {
  await signOut(auth);

  localStorage.removeItem(key.token);
  localStorage.removeItem(key.user);
  localStorage.removeItem(key.userEnterprises);
  localStorage.removeItem(key.stations);
  localStorage.removeItem(key.refreshToken);
  localStorage.removeItem(key.credentials);

  return;
}
