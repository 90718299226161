import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { IoCogOutline, IoGridOutline } from 'react-icons/io5';
import { GoPeople } from 'react-icons/go';
import { LuWrench } from 'react-icons/lu';
import { IconType } from 'react-icons';
import { ReactNode } from 'react';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import signOutUser from '../firebase/auth/signOut';
import { useAuth } from '../contexts/AuthContext';

interface LinkItemProps {
  name: string;
  route: string;
  icon: IconType;
}

const LinkItems: Array<LinkItemProps> = [
  {
    name: 'Dashboard',
    route: '/dashboard',
    icon: IoGridOutline,
  },
  // {
  //   name: 'Clientes',
  //   route: '/clientes',
  //   icon: GoPeople,
  // },
  // {
  //   name: 'Integrações',
  //   route: '/integracoes',
  //   icon: IoCogOutline,
  // },
  // {
  //   name: 'Manutenção',
  //   route: '/manutencao',
  //   icon: LuWrench,
  // },
];

export default function HeaderAndSidebar({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div className="w-full  bg-bgDark flex flex-col relative ">
      <SidebarContent />
      <Header />
      <div className="mx-auto max-w-[1440px] pl-[112px] py-4 px-6 h-[calc(100vh-64px)]">
        {children}
      </div>
    </div>
  );
}

const SidebarContent = () => {
  const location = useLocation();

  return (
    <aside className="flex xl:flex flex-col bg-bgSecondary text-white w-[88px] min-h-screen px-4 py-6 gap-8 fixed left-0 border-solid border-r-[1px] border-border">
      <img
        src="/logo-sweg.svg"
        width={32}
        height={28}
        alt="Logo Sweg"
        className="self-center mb-4"
      />

      {LinkItems.map((link) => {
        return location.pathname === '/primeiro-acesso' ? (
          <div key={link.name} className="flex items-center gap-3 px-4">
            {link.icon && (
              <link.icon className="w-6 h-6 text-primary text-white" />
            )}
          </div>
        ) : (
          <Link to={link.route} onClick={() => {}} key={link.name}>
            {location.pathname === link.route ? (
              <div className="flex items-center gap-3 px-4 py-3 bg-bgDark rounded-md">
                {link.icon && (
                  <link.icon className="w-6 h-6 text-primary text-swegGradient " />
                )}
              </div>
            ) : (
              <div className="flex items-center gap-3 px-4">
                {link.icon && (
                  <link.icon className="w-6 h-6 text-primary text-white" />
                )}
              </div>
            )}
          </Link>
        );
      })}
    </aside>
  );
};

const Header = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  function getInitials(name: string) {
    const names = name.split(' ');
    if (names.length > 1) {
      const initials = names[0].charAt(0) + names[names.length - 1].charAt(0);
      return initials.toUpperCase();
    }
    // Retorna as duas primeiras letras se o usuário tiver apenas um nome
    return name.substring(0, 2).toUpperCase();
  }

  return (
    <div className="flex w-full bg-bgDark items-center justify-between h-16 px-6 py-3 border-solid border-[1px] border-mediumGray">
      <div></div>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Avatar>
            <AvatarImage src="óla" />
            <AvatarFallback>
              {getInitials(user?.name ? user.name : '')}
            </AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel>
            <Button
              variant={'ghost'}
              onClick={async () => {
                await signOutUser();
                window.location.reload();
              }}
            >
              Logout
            </Button>
          </DropdownMenuLabel>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
