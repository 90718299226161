import * as React from 'react';

import { PatternFormat } from 'react-number-format';
import { StateProps } from '../../dtos';
import getValidationErrors from '../../utils/yupValidation';
import { useToast } from '../ui/use-toast';
import { useAuth } from '../../contexts/AuthContext';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Icons } from '../icons';
import Modal from '../Modal';
import * as Yup from 'yup';
import api from '../../services/api';
import { key } from '../../config/key';
import { useState } from 'react';
import { useEnterprises } from '../../contexts/EnterpriseContext';

interface RegisterEnterpriseProps {
  onChangeStep: () => void;
}

export function StepOne({ onChangeStep }: RegisterEnterpriseProps) {
  const { toast } = useToast();
  const [openModalRegister, setOpenModalRegister] = useState(false);
  // const [openModalLogo, setOpenModalLogo] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<StateProps>({} as StateProps);

  const [values, setValues] = useState({} as StateProps);

  const { user } = useAuth();
  const { updateUserEnterprise } = useEnterprises();

  const cnpjRegex = new RegExp(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/);

  async function registerEnterprise() {
    setIsLoading(true);
    setErrors({});

    try {
      const registerSchema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        cnpjMask: Yup.string()
          .matches(cnpjRegex, 'Número do cnpj inválido.')
          .required('Cnpj obrigatório'),
        site: Yup.string()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Digite um site válido'
          )
          .required('Site obrigatório'),
      });

      await registerSchema.validate(values, {
        abortEarly: false,
      });

      const body = {
        name: values?.name,
        cnpj: values?.cnpj,
        site: values?.site,
      };

      const response = await api.post('/enterprises', body);

      if (response.data) {
        localStorage.setItem(
          key.userEnterprises,
          JSON.stringify(response.data)
        );

        updateUserEnterprise(response.data);
      }

      toast({
        variant: 'success',
        duration: 3000,
        title: 'Empresa registrada com sucesso.',
      });

      onChangeStep();

      return response;
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));

        return;
      }
      if (err.response) {
        return toast({
          variant: 'error',
          duration: 3000,
          title:
            err?.response?.data?.message ||
            'Não foi possível registrar a empresa.',
        });
      }
      return toast({
        variant: 'error',
        duration: 3000,
        title:
          err?.response?.data?.message ||
          'Não foi possível registrar a empresa.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex mt-8 items-center justify-center">
      {/* <Modal
            isOpen={openModalLogo}
            openChange={setOpenModalLogo}
            onSubmit={() => {}}
            title="Logo da empresa"
            buttonTitle="Registrar"
          >
            <div className="flex w-full items-center justify-center mb-4">
              <Avatar className="w-[80px] h-[80px] ">
                <AvatarImage src="/uploadImage.svg" width={80} height={80} />
              </Avatar>
            </div>

            <div className="flex w-full items-center justify-center mb-4">
              <div className="flex h-[40px] px-4 py-2 bg-bgPrimaryDark border-solid border-foreground rounded-sm items-center justify-start gap-2 cursor-pointer">
                <RxUpload className="ml-2 h-4 w-4 cursor-pointer" />
                <p className="text-sm cursor-pointer">Fazer upload da imagem</p>
                <Input
                  type="file"
                  className="opacity-0 absolute cursor-pointer w-[220px]"
                  title=""
                />
              </div>
            </div>

            <p className="text-sm text-center text-textGray">
              JPG, PNG ou SVG até 1.5Mb
            </p>
        </Modal> */}
      <Modal
        isOpen={openModalRegister}
        openChange={setOpenModalRegister}
        onSubmit={registerEnterprise}
        title="Cadastrar empresa"
        isIcon={true}
        loading={isLoading}
        isDisabled={!values.name || !values.cnpj || !values.site}
      >
        <Label htmlFor="name" className="mb-2 mt-5">
          Nome da empresa
        </Label>
        <Input
          id="name"
          placeholder="Insira o nome da empresa"
          type="text"
          name="name"
          autoCorrect="off"
          disabled={isLoading}
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
          error={errors?.name}
        />

        <Label htmlFor="name" className="mb-2 mt-5">
          CNPJ
        </Label>
        <PatternFormat
          placeholder="00.000.000/0000-00"
          name="cnpj"
          customInput={Input}
          error={errors?.cnpjMask}
          value={values?.cnpjMask}
          onValueChange={(value) =>
            setValues({
              ...values,
              cnpj: value?.value,
              cnpjMask: value?.formattedValue,
            })
          }
          type="tel"
          format="##.###.###/000#-##"
          mask="_"
        />

        <Label htmlFor="site" className="mb-2 mt-5">
          Site da empresa
        </Label>
        <Input
          id="site"
          placeholder="www.suaempresa.com.br"
          type="text"
          name="site"
          autoCorrect="off"
          disabled={isLoading}
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
          error={errors?.site}
        />
      </Modal>
      <div className="md:w-[262px] xl:w-[362px] p-7 bg-bgSecondary rounded-s-2xl h-[170px] xl:h-[220px] border-[1px] border-mediumGray border-solid flex flex-col justify-between shadow-shadowCards z-10 relative">
        <div className="flex  justify-between">
          <p className="uppercase text-xs text-textColorSidebar">Passo 1</p>
          <Icons.Building className="h-8 w-8" />
        </div>
        <h1 className="text-2xl font-semibold text-lightGray w-[136px]">
          Cadastrar empresa
        </h1>
        <div
          className="w-[48px] h-[48px] rounded-full absolute bottom-[-24px] right-[28px] bg-gradient-to-b from-lightBlueGradient from-7% to-yellowGradient to-86% flex items-center cursor-pointer justify-center"
          onClick={() => setOpenModalRegister(!openModalRegister)}
        >
          <Icons.CareRight className="h-8 w-8 " />
        </div>
      </div>
      <div className="md:w-[214px] xl:w-[314px] p-7 bg-bgSecondary border-y-[1px] border-mediumGray border-solid h-[146px] xl:h-[196px] flex flex-col justify-between">
        <div className="flex justify-between">
          <p className="uppercase text-xs text-disabledGray">Passo 2</p>
          <Icons.PlugDisabled className="h-8 w-8" />
        </div>
        <h1 className="text-disabledGray">Integrar inversores</h1>
      </div>
      <div className="md:w-[214px]  xl:w-[314px] p-7 bg-bgSecondary border-[1px] border-mediumGray border-solid h-[146px] xl:h-[196px] flex flex-col justify-between rounded-e-2xl">
        <div className="flex justify-between">
          <p className="uppercase text-xs text-disabledGray">Passo 3</p>
          <Icons.ZapDisabled className="h-8 w-8" />
        </div>
        <h1 className="text-disabledGray">Adicionar usinas</h1>
      </div>
    </div>
  );
}
