import { createContext, useContext, useEffect, useState } from 'react';
import { key } from '../config/key';
import { CredentialDTO } from '../dtos';
import api from '../services/api';
import { useEnterprises } from './EnterpriseContext';

type CredentialsContextData = {
  credentials: CredentialDTO[];
  currentCredentials: CredentialDTO;
  credentialLoading?: boolean;
  updateCredential: (credential: CredentialDTO) => void;
};

const CredentialsContext = createContext({} as CredentialsContextData);

const CredentialProvider = ({ children }: { children: React.ReactNode }) => {
  const [credentialLoading, setCredentialLoading] = useState(true);

  const [credentials, setCredentials] = useState<CredentialDTO[]>(
    [] as CredentialDTO[]
  );
  const [currentCredentials, setCurrentCredentials] = useState<CredentialDTO>(
    {} as CredentialDTO
  );
  const { currentEnterprise } = useEnterprises();
  const updateCredential = (credential: CredentialDTO) => {
    setCredentials([credential]);
    setCurrentCredentials(credential);
  };
  const getUserCredential = async () => {
    setCredentialLoading(true);

    try {
      const userCredentialstorage = localStorage.getItem(key.credentials);

      if (userCredentialstorage) {
        setCredentials(JSON.parse(userCredentialstorage));
        setCurrentCredentials(JSON.parse(userCredentialstorage)[0]);
      } else {
        const responseApi = await api.get(
          `/credentials/enterprise/${currentEnterprise?.enterpriseId}`
        );

        if (responseApi) {
          setCredentials(responseApi.data);
          setCurrentCredentials(responseApi.data[0]);
          localStorage.setItem(
            key.credentials,
            JSON.stringify(responseApi.data)
          );
        }

        return responseApi.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCredentialLoading(false);
    }
  };

  useEffect(() => {
    if (currentEnterprise?.enterpriseId) {
      getUserCredential();
    }
  }, [currentEnterprise?.enterpriseId]);

  return (
    <CredentialsContext.Provider
      value={{
        credentials,
        currentCredentials,
        credentialLoading,
        updateCredential,
      }}
    >
      {children}
    </CredentialsContext.Provider>
  );
};

function useCredentials(): CredentialsContextData {
  const context = useContext(CredentialsContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { CredentialProvider, useCredentials };
