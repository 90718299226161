import * as React from 'react';

import * as Yup from 'yup';
import { CredentialDTO, StateProps } from '../../dtos';
import getValidationErrors from '../../utils/yupValidation';
import { useToast } from '../ui/use-toast';
import { useAuth } from '../../contexts/AuthContext';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Icons } from '../icons';
import Modal from '../Modal';
import { Avatar, AvatarImage } from '../ui/avatar';
import { useEffect, useState } from 'react';
import api from '../../services/api';
import { key } from '../../config/key';
import { useCredentials } from '../../contexts/CredentialContext';

interface RegisterOperatorProps {
  onChangeStep: () => void;
}

export function StepTwo({ onChangeStep }: RegisterOperatorProps) {
  const { user } = useAuth();
  const { toast } = useToast();
  const { updateCredential } = useCredentials();
  const [openModalOperator, setOpenModalOperator] = useState(false);
  const [operatorSelected, setOperatorSelected] = useState({
    name: '',
    id: '',
    avatarUrl: '',
  });
  const [openModalCredencial, setOpenModalCredencial] = useState(false);
  const [errors, setErrors] = useState<StateProps>({} as StateProps);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [values, setValues] = useState({
    enterpriseId: '',
    account: '',
    password: '',
    type: '',
  });

  const operatorsList = [
    {
      name: 'Fusion Solar',
      id: 'FUSIONSOLAR',
      avatarUrl: '/fusionSolar.svg',
    },
  ];

  const getUserEnterprise = async (token: string) => {
    const response = await api.get(`/user-enterprises/mine`);

    if (response.data.length > 0) {
      setValues({ ...values, enterpriseId: response.data[0].enterpriseId });
    }
  };

  useEffect(() => {
    if (user?.accessToken) {
      getUserEnterprise(user?.accessToken);
    }
  }, []);

  async function postOperator() {
    if (!operatorSelected.id) {
      return toast({
        variant: 'error',
        duration: 3000,
        title: 'Selecione uma operadora',
      });
    }

    try {
      setValues({ ...values, type: operatorSelected.id });
      setOpenModalOperator(false);
    } catch (err: any) {
      return toast({
        variant: 'error',
        duration: 3000,
        title:
          err?.response?.data?.message ||
          'Não foi possível registrar a empresa.',
      });
    } finally {
      setOpenModalCredencial(true);
    }
  }

  async function postCredential(token: string) {
    setIsLoading(true);
    setErrors({});

    try {
      const registerSchema = Yup.object().shape({
        account: Yup.string().required('Usuário obrigatório'),
        password: Yup.string().required('Senha obrigatória'),
      });

      await registerSchema.validate(values, {
        abortEarly: false,
      });

      const response = await api.post('/credentials', values);

      localStorage.setItem(key.credentials, JSON.stringify(response.data));
      updateCredential(response.data);

      toast({
        variant: 'success',
        duration: 3000,
        title: 'Credencial registrada com sucesso.',
      });

      onChangeStep();

      return response;
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        setErrors(getValidationErrors(err));

        return;
      }
      if (err.response) {
        return toast({
          variant: 'error',
          duration: 3000,
          title:
            err?.response?.data?.message ||
            'Não foi possível adicionar a credential.',
        });
      }
      return toast({
        variant: 'error',
        duration: 3000,
        title:
          err?.response?.data?.message ||
          'Não foi possível adicionar a credential',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex mt-8 items-center justify-center">
      <Modal
        isOpen={openModalOperator}
        openChange={setOpenModalOperator}
        onSubmit={() => postOperator()}
        title="Selecione a [operadora]"
        buttonTitle="Avançar"
        isIcon
      >
        <Input
          id="email"
          placeholder="Buscar"
          type="text"
          autoCapitalize="none"
          autoComplete="email"
          autoCorrect="off"
        />

        <div className="flex flex-col my-4">
          {operatorsList.map((operator) => {
            return (
              <div
                className="flex gap-2 py-3 border-solid border-b-[1px] border-border cursor-pointer"
                key={operator.id}
                onClick={() => setOperatorSelected(operator)}
              >
                <Avatar className="w-[24px] h-[24px]">
                  <AvatarImage
                    src={operator?.avatarUrl}
                    width={24}
                    height={24}
                  />
                </Avatar>
                <p className="text-white">{operator.name}</p>

                {operator.id === operatorSelected.id && (
                  <Icons.CheckCircle className="h-6 w-6" />
                )}
              </div>
            );
          })}
        </div>
      </Modal>

      <Modal
        isOpen={openModalCredencial}
        openChange={setOpenModalCredencial}
        onSubmit={() => {
          if (user?.accessToken) {
            postCredential(user?.accessToken);
          }
        }}
        title="Adicionar credencial"
        buttonTitle="Adicionar"
        loading={isLoading}
      >
        <Label htmlFor="account" className="mb-2 mt-5">
          Usuário
        </Label>
        <Input
          id="account"
          placeholder="Insira seu nome de usuário no portal"
          type="text"
          name="account"
          autoCorrect="off"
          disabled={isLoading}
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
          error={errors.account}
        />

        <Label htmlFor="password" className="mb-2 mt-5">
          Senha
        </Label>

        <Input
          id="password"
          placeholder="******"
          name="password"
          type="password"
          autoCorrect="off"
          disabled={isLoading}
          onChange={(e) =>
            setValues({ ...values, [e.target.name]: e.target.value })
          }
          error={errors.password}
        />
      </Modal>

      <div className="w-[314px] p-7 bg-bgSecondary rounded-s-2xl h-[196px] border-[1px] border-mediumGray border-solid flex flex-col justify-between">
        <div className="flex  justify-between">
          <p className="uppercase text-xs text-disabledGray">Passo 1</p>
          <Icons.BuildingDisabled className="h-8 w-8" />
        </div>
        <div className="w-full flex items-center justify-center">
          <Icons.CheckCircle className="h-10 w-10" />
        </div>
        <h1 className="text-disabledGray text-center">Cadastrar empresa</h1>
      </div>
      <div className="w-[362px] p-7 bg-bgSecondary border-y-[1px] border-mediumGray border-solid h-[220px] flex flex-col justify-between shadow-shadowCards z-10 relative">
        <div className="flex justify-between">
          <p className="uppercase text-xs text-textColorSidebar">Passo 2</p>
          <Icons.Plug className="h-8 w-8" />
        </div>
        <h1 className="text-2xl font-semibold text-lightGray w-[136px]">
          Integrar inversores
        </h1>
        <div
          className="w-[48px] h-[48px] rounded-full absolute bottom-[-24px] right-[28px] bg-gradient-to-b from-lightBlueGradient from-7% to-yellowGradient to-86% flex items-center cursor-pointer justify-center"
          onClick={() => setOpenModalOperator(!openModalOperator)}
        >
          <Icons.CareRight className="h-8 w-8 " />
        </div>
      </div>
      <div className="w-[314px] p-7 bg-bgSecondary border-[1px] border-mediumGray border-solid h-[196px] flex flex-col justify-between rounded-e-2xl">
        <div className="flex justify-between">
          <p className="uppercase text-xs text-disabledGray">Passo 3</p>
          <Icons.ZapDisabled className="h-8 w-8" />
        </div>
        <h1 className="text-disabledGray">Adicionar usinas</h1>
      </div>
    </div>
  );
}
