import { useState } from 'react';
import HeaderAndSidebar from '../components/HeaderSidebar';
import { Icons } from '../components/icons';
import { StationCard } from '../components/Station/card';
import { Button } from '../components/ui/button';

const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

export default function Station() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dateRange, setDateRange] = useState('month');

  const handlePrevClick = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      if (dateRange === 'month') {
        newDate.setMonth(prevDate.getMonth() - 1);
      }
      // Add logic for 'day', 'week', 'year'
      return newDate;
    });
  };

  const handleNextClick = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      if (dateRange === 'month') {
        newDate.setMonth(prevDate.getMonth() + 1);
      }
      // Add logic for 'day', 'week', 'year'
      return newDate;
    });
  };

  const handleDateRangeChange = (range: any) => {
    setDateRange(range);
  };

  // This function will generate the days to be displayed based on the selected date range
  const generateDays = () => {
    if (dateRange === 'month') {
      const daysInCurrentMonth = getDaysInMonth(
        currentDate.getFullYear(),
        currentDate.getMonth()
      );
      return Array.from(
        { length: daysInCurrentMonth },
        (_, index) => index + 1
      );
    }
    // Add logic for 'day', 'week', 'year'
    return [];
  };

  const daysToDisplay = generateDays();

  // Formatting date for display
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const displayDate = `${
    monthNames[currentDate.getMonth()]
  } ${currentDate.getFullYear()}`;

  const getLineColor = (day: number, currentDate: Date) => {
    const dayDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    return dayDate < currentDate ? 'bg-white' : 'bg-gray-500'; // Replace bg-gray-500 with your actual gray color class
  };

  return (
    <HeaderAndSidebar>
      <div className="w-full flex flex-col p-8">
        <div className="gap-4 flex justify-between mb-8 ">
          <div>
            <h1 className="text-4xl text-white">UFV Villa Vetri</h1>
            <div className="flex gap-6">
              <div className="flex items-center space-x-2">
                <Icons.Location className="h-4 w-4" />
                <p className="text-textColorSidebar">Jundiai, SP</p>
              </div>
              <div className="flex items-center space-x-2">
                <Icons.UserStation className="h-4 w-4" />
                <p className="text-textColorSidebar">Jose Rocha Valadares</p>
              </div>
              <div className="flex items-center space-x-2">
                <Icons.CalendarStation className="h-4 w-4" />
                <p className="text-textColorSidebar">15/04/2022</p>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="flex items-center space-x-2">
                <Icons.PowerStation className="h-4 w-4" />
                <p className="text-textColorSidebar">
                  Potencia instalada 2000kWp
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <Icons.ChartStation className="h-4 w-4" />
                <p className="text-textColorSidebar">
                  Geracao prevista 200 MWh
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <Icons.CoinStation className="h-4 w-4" />
                <p className="text-textColorSidebar">
                  Investimento R$17.600,00
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <Button className="flex bg-bgCardsBlack text-white hover:bg-bgCardsBlack/80 items-center gap-4 rounded-lg border border-solid border-mediumGray">
              <div className="rounded-full w-2 h-2 bg-green-600 "></div>
              <h1 className="text-white">Operacao Normal</h1>
            </Button>
            <Button className="bg-bgCardsBlack text-white hover:bg-bgCardsBlack/80 rounded-lg border border-solid border-mediumGray">
              Notificacoes
            </Button>
          </div>
        </div>

        <div className="flex w-full items-center justify-between gap-2 mb-8">
          <Button className="w-full bg-bgPrimaryDark text-white rounded-t rounded-b-none border-b-2 border-yellow  hover:border-b-2 hover:bg-bgPrimaryDark/80 hover:border-yellow">
            Monitoramento
          </Button>
          <Button className="w-full bg-bgCardsBlack text-textGray rounded-t rounded-b-none hover:bg-bgPrimaryDark/80">
            Análise técnica
          </Button>
          <Button className="w-full bg-bgCardsBlack text-textGray rounded-t rounded-b-none hover:bg-bgPrimaryDark/80">
            Manutencao
          </Button>
        </div>

        <div className="bg-borderGraySecondary rounded-xl p-6 mb-8">
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-white text-2xl">Desempenho da usina</h1>
            <div className="flex gap-6 ">
              <div className="flex gap-2">
                <Button
                  className="w-full bg-bgPrimaryDark text-textGray  hover:bg-bgPrimaryDark/80"
                  onClick={handlePrevClick}
                >
                  <Icons.SingleArrowLeft className="h-4 w-4" />
                </Button>
                <Button className="w-full bg-bgPrimaryDark text-textGray  hover:bg-bgPrimaryDark/80 gap-2 whitespace-nowrap ">
                  <Icons.Calendar className="h-4 w-4" />
                  {displayDate}
                </Button>
                <Button
                  className="w-full bg-bgPrimaryDark text-textGray  hover:bg-bgPrimaryDark/80"
                  onClick={handleNextClick}
                >
                  <Icons.SingleArrowRight className="h-4 w-4" />
                </Button>
              </div>
              <div className="flex gap-2">
                <Button
                  className="w-full bg-bgPrimaryDark text-white rounded-t rounded-b-none border-b-2 border-yellow  hover:border-b-2 hover:bg-bgPrimaryDark/80 hover:border-yellow"
                  onClick={() => handleDateRangeChange('month')}
                >
                  Mes
                </Button>
                <Button
                  className="w-full bg-borderGraySecondary text-textGray rounded-t rounded-b-none hover:bg-bgPrimaryDark/80"
                  onClick={() => handleDateRangeChange('day')}
                >
                  Dia
                </Button>
                <Button
                  className="w-full bg-borderGraySecondary text-textGray rounded-t rounded-b-none hover:bg-bgPrimaryDark/80"
                  onClick={() => handleDateRangeChange('week')}
                >
                  Semana
                </Button>
                <Button
                  className="w-full bg-borderGraySecondary text-textGray rounded-t rounded-b-none hover:bg-bgPrimaryDark/80"
                  onClick={() => handleDateRangeChange('year')}
                >
                  Ano
                </Button>
              </div>
            </div>
          </div>
          <div className="flex bg-bgCardsBlack rounded-lg py-3 px-4 border border-mediumGray items-start justify-between mb-4">
            <div className="flex gap-3">
              <Icons.Flag />
              <div>
                <p className="text-yellow text-[13px]">Dica</p>
                <p className="text-white text-[13px]">
                  Clique nos pontos do gráfico e entenda os motivos do
                  desempenho apresentado.
                </p>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <p className="text-lightGray text-[13px]">
                Não mostrar novamente
              </p>
              <Icons.Close className="cursor-pointer" />
            </div>
          </div>
          <div className="flex relative items-center justify-center h-full gap-3 pt-9 mb-4 ">
            <div className="absolute top-1/2 transform -translate-y-1/2 left-0 right-0 h-0.5">
              {daysToDisplay.map((day) => (
                <span
                  key={day}
                  className={`absolute h-0.5 ${getLineColor(day, currentDate)}`}
                  style={{
                    left: `${((day - 1) / daysToDisplay.length) * 100}%`,
                    width: `${100 / daysToDisplay.length}%`,
                  }}
                ></span>
              ))}
            </div>
            {daysToDisplay.map((day) => (
              <div className="flex flex-col items-center gap-3 z-10" key={day}>
                <div className="flex bg-bgCardsBlack rounded-xl h-[64px] w-[15px] items-center justify-center">
                  <div className="w-3 h-3 rounded-full bg-bgGreen"></div>
                </div>
                <Icons.Sun className="w-5 h-5" />
                <p className="text-grayTertiary text-[13px]">{day}</p>
              </div>
            ))}
          </div>
          <div className="flex pt-2 gap-8 items-center justify-center mb-8">
            <div className="flex gap-2 items-center">
              <div className="w-[68px] h-[1px] bg-lightGray"></div>
              <p className="text-textColorSidebar">
                Energia contratada 200 KWh
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 rounded-full bg-lightGray"></div>
              <p className="text-textColorSidebar">Atingiu</p>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 rounded-full bg-bgGreen"></div>
              <p className="text-textColorSidebar">Ultrapassou</p>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 rounded-full bg-bgRed"></div>
              <p className="text-textColorSidebar">Não atingiu</p>
            </div>
          </div>
          <div className="flex gap-4 w-full">
            <StationCard description="Perfomance">
              <div className="flex items-end justify-between">
                <div className="flex items-end">
                  <h1 className="font-light text-[32px] leading-none text-end text-white">
                    58
                  </h1>
                  <p className="text-sm text-white">%</p>
                </div>
                <div className="bg-bgError rounded-full py-[2px] px-[10px]">
                  <p className="text-[11px] text-black">Abaixo do esperado</p>
                </div>
              </div>
            </StationCard>
            <StationCard description="Rentabilidade">
              <div className="flex items-end gap-2">
                <div className="flex items-end">
                  <p className="text-sm font-normal text-white">R$</p>
                  <h1 className="font-light text-[32px] leading-none text-end text-white">
                    77.895
                  </h1>
                </div>
                <div className="flex items-end gap-[2px]">
                  <Icons.ArrowUpRigth className="w-[10px] h-[10px]" />
                  <p className="text-xs font-light text-bgGreen leading-none text-end">
                    4%
                  </p>
                </div>
              </div>
            </StationCard>
            <StationCard description="Potência instantânea">
              <div className="flex items-end">
                <h1 className="font-light text-[32px] leading-none text-end text-white">
                  176
                </h1>
                <p className="text-sm font-normal text-white">MWh</p>
              </div>
            </StationCard>
          </div>
        </div>

        <div className="flex pt-4 gap-12 w-full  ">
          <div className="w-full bg-borderGraySecondary rounded-xl p-6 items-center">
            <div className="flex mb-6">
              <h1 className="text-white text-xl max-w-[412px]">
                De acordo com a previsão do tempo a{' '}
                <b>tendência para os próximos 7 dias</b> é de:{' '}
              </h1>
              <div className="flex items-center gap-4">
                <div className="flex w-10 h-10 bg-bgGreen rounded-full items-center justify-center">
                  <Icons.ArrowUpRigthBlack className="w-[14px] h-[14px] text-bgDark" />
                </div>
                <h1 className="text-bgGreen font-normal leading-[28px]">
                  Alta perfomance
                </h1>
              </div>
            </div>
            <div className="py-4 px-6 flex bg-bgDark justify-between rounded-xl gap-[10px] mb-6">
              <div className="flex flex-col gap-2 items-center">
                <p className="text-white text-sm">Sunday</p>
                <Icons.Sun className="h-6 w-6" />
              </div>
              <div className="flex flex-col gap-2 items-center">
                <p className="text-white text-sm">Monday</p>
                <Icons.Sun className="h-6 w-6" />
              </div>
              <div className="flex flex-col gap-2 items-center">
                <p className="text-white text-sm">Tuesday</p>
                <Icons.Sun className="h-6 w-6" />
              </div>
              <div className="flex flex-col gap-2 items-center">
                <p className="text-white text-sm">Wednesday</p>
                <Icons.Sun className="h-6 w-6" />
              </div>
              <div className="flex flex-col gap-2 items-center">
                <p className="text-white text-sm">Thursday</p>
                <Icons.Sun className="h-6 w-6" />
              </div>
              <div className="flex flex-col gap-2 items-center">
                <p className="text-white text-sm">Friday</p>
                <Icons.Sun className="h-6 w-6" />
              </div>
              <div className="flex flex-col gap-2 items-center">
                <p className="text-white text-sm">Saturday</p>
                <Icons.Sun className="h-6 w-6" />
              </div>
            </div>
            <div className="flex w-full items-center justify-center">
              <p className="text-lightGray text-[15px]">
                Entenda todos os fatores que podem influenciar o desempenho.
              </p>
            </div>
          </div>
          <div className="w-[392px]">
            <h1 className="text-white text-xl mb-4">Relatórios</h1>
            <div className="flex items-center justify-between border-b border-backgroundSidebar ">
              <div className="flex gap-2">
                <Icons.File className="h-4 w-4" />
                <p className="text-textColorSidebar text-sm">
                  Relatorio 21-02-2024{' '}
                </p>
              </div>
              <Button
                variant="ghost"
                className="text-yellow text-[13px] p-0 hover:bg-transparent hover:text-yellow"
              >
                Baixar
              </Button>
            </div>
            <div className="flex items-center justify-between border-b border-backgroundSidebar  ">
              <div className="flex gap-2">
                <Icons.File className="h-4 w-4" />
                <p className="text-textColorSidebar text-sm">
                  Relatorio 21-02-2024{' '}
                </p>
              </div>
              <Button
                variant="ghost"
                className="text-yellow text-[13px] p-0 hover:bg-transparent hover:text-yellow"
              >
                Baixar
              </Button>
            </div>
            <div className="flex items-center justify-between border-b border-backgroundSidebar ">
              <div className="flex gap-2">
                <Icons.File className="h-4 w-4" />
                <p className="text-textColorSidebar text-sm">
                  Relatorio 21-02-2024{' '}
                </p>
              </div>
              <Button
                variant="ghost"
                className="text-yellow text-[13px] p-0 hover:bg-transparent hover:text-yellow"
              >
                Baixar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </HeaderAndSidebar>
  );
}
