import { useEffect, useState } from 'react';
import { CiPlug1 } from 'react-icons/ci';
import { IoAdd, IoEllipsisVerticalSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import AlarmIndicator from '../components/AlarmColors';
import HeaderAndSidebar from '../components/HeaderSidebar';
import { Icons } from '../components/icons';
import Modal from '../components/Modal';
import { Card } from '../components/Overview/card';
import Pagination from '../components/Pagination';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../components/ui/table';
import { toast } from '../components/ui/use-toast';
import { useCredentials } from '../contexts/CredentialContext';
import { useEnterprises } from '../contexts/EnterpriseContext';
import { useStations } from '../contexts/StationsContext';
import { ResumeStationsDTO, StationDTO } from '../dtos';
import { AlarmState } from '../enum';
import api from '../services/api';

export default function Dashboard() {
  const { stations } = useStations();
  const [stationsSelected, setStationsSelected] = useState([] as StationDTO[]);
  const navigate = useNavigate();
  const [resume, setResume] = useState({} as ResumeStationsDTO);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { currentEnterprise } = useEnterprises();
  const { currentCredentials } = useCredentials();
  const [order, setOrder] = useState('plantName');
  const [loading, setLoading] = useState(false);
  const [openModalAddUsina, setOpenModalAddUsina] = useState(false);
  const [openModalStation, setOpenModalStation] = useState(false);
  const [userStations, setUserStations] = useState([] as StationDTO[]);
  const [filter, setFilter] = useState('');
  const [filteredStations, setFilteredStations] = useState([]);

  const perPage = 10;

  async function getStationsData() {
    setLoading(true);
    try {
      const response = await api.get(
        `/stations/resume-dashboard/${currentEnterprise?.enterpriseId}`
      );

      setResume(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }

  const getRandomAlarmState = (): AlarmState => {
    const alarmStates = Object.values(AlarmState);

    const randomIndex = Math.floor(Math.random() * alarmStates.length);

    return alarmStates[randomIndex];
  };

  async function getStations() {
    setLoading(true);
    try {
      const response = await api.get(
        `/stations/pagination/${currentEnterprise?.enterpriseId}?page=${page}&per_page=${perPage}&order_by=${order}`
      );

      const stationsWithRandomAlarms = response.data?.items.map(
        (station: StationDTO) => ({
          ...station,
          alarm: getRandomAlarmState(),
        })
      );

      setUserStations(stationsWithRandomAlarms);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (currentEnterprise?.enterpriseId && page && perPage && order) {
      getStations();
    }
  }, [page, perPage, order, currentEnterprise?.enterpriseId]);

  useEffect(() => {
    if (currentEnterprise?.enterpriseId) {
      getStationsData();
    }
  }, [currentEnterprise?.enterpriseId]);

  useEffect(() => {
    console.log('dash on');
  }, []);

  async function postStations() {
    try {
      const stationsToSend = stationsSelected.map((station) => {
        return {
          enterpriseId: currentCredentials.enterpriseId,
          type: currentCredentials.type,
          capacity: station.capacity,
          contactMethod: station.contactMethod,
          contactPerson: station.contactPerson,
          gridConnectionDate: station.gridConnectionDate,
          latitude: station.latitude,
          longitude: station.longitude,
          plantAddress: station.plantAddress,
          plantCode: station.plantCode,
          plantName: station.plantName,
        };
      });
      const response = await api.post('/stations', stationsToSend);

      toast({
        variant: 'success',
        duration: 3000,
        title: 'Credencial registrada com sucesso.',
      });

      setOpenModalStation(false);
      getStations();
      getStationsData();
      return response;
    } catch (err: any) {
      return toast({
        variant: 'error',
        duration: 3000,
        title:
          err?.response?.data?.message ||
          'Não foi possível registrar a credencial.',
      });
    } finally {
    }
  }

  const handleSelectStation = (station: StationDTO) => {
    const index = stationsSelected.findIndex(
      (selectedStation) => selectedStation.plantCode === station.plantCode
    );
    let newSelectedStations = [...stationsSelected];

    if (index > -1) {
      newSelectedStations.splice(index, 1);
    } else {
      newSelectedStations.push(station);
    }
    setStationsSelected(newSelectedStations);
  };

  const handleStationData = (stationId: string) => {
    navigate(`/usina/${stationId}`);
  };

  return (
    <HeaderAndSidebar>
      <Modal
        isOpen={openModalAddUsina}
        openChange={setOpenModalAddUsina}
        onSubmit={() => setOpenModalStation(true)}
        title="Adicionar usina"
        subtitle="Como deseja adiiconar a nova usina?"
        isIcon
        footer={false}
      >
        <div className="flex gap-6">
          <div
            className="flex flex-col bg-white p-4 rounded-md w-[195px] cursor-pointer"
            onClick={() => {
              getStations();
              setOpenModalStation(true);
            }}
          >
            <CiPlug1 className="h-5 w-5 text-backgroundPrimary mb-2" />
            <h1 className="text-backgroundPrimary font-semibold">
              Importar dados do integrador
            </h1>
          </div>
          {/* <div className="flex flex-col bg-white p-4 rounded-md w-[195px] cursor-pointer">
            <RxPencil2 className="h-5 w-5 text-backgroundPrimary mb-2" />
            <h1 className="text-backgroundPrimary font-semibold">
              Importar dados do integrador
            </h1>
          </div> */}
        </div>
      </Modal>
      <Modal
        isOpen={openModalStation}
        openChange={setOpenModalStation}
        onSubmit={() => {
          postStations();
        }}
        title="Selecione a [operadora]"
        buttonTitle="Avançar"
        isIcon
      >
        <Input
          id="email"
          placeholder="Buscar"
          type="text"
          autoCapitalize="none"
          autoComplete="email"
          autoCorrect="off"
        />

        {loading ? (
          <div className="flex w-full  items-center justify-center h-40">
            <Icons.Spinner className=" h-6 w-6 animate-spin text-white" />
          </div>
        ) : (
          <div className="flex flex-col my-4 max-h-[280px] overflow-auto ">
            {stations.map((station: any) => {
              const isDisabled = station.disabled;
              return (
                <div
                  className={`flex gap-2 py-3 border-solid border-b-[1px] border-border cursor-pointer hover:bg-borderGraySecondary/80 ${
                    isDisabled ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  key={station?.id}
                  onClick={() => !isDisabled && handleSelectStation(station)}
                >
                  {/* <Avatar className="w-[24px] h-[24px] ">
                  <AvatarImage
                    src={station?.plantName}
                    width={24}
                    height={24}
                  />
                </Avatar> */}
                  <p className="text-white">{station?.plantName}</p>
                  {stationsSelected.some(
                    (selectedStation) =>
                      selectedStation.plantCode === station.plantCode ||
                      isDisabled
                  ) && <Icons.CheckCircle className="h-6 w-6" />}
                </div>
              );
            })}
          </div>
        )}
      </Modal>
      <div className="w-full flex items-center justify-between mb-8">
        <h1 className="text-3xl font-semibold text-white">Usinas</h1>
        <Button
          className="bg-borderGraySecondary text-white gap-2  hover:bg-borderGraySecondary/80"
          onClick={() => setOpenModalAddUsina(!openModalAddUsina)}
        >
          <IoAdd className="h-5 w-5 text-white" />
          Nova usina
        </Button>
      </div>
      {loading ? (
        <div className="flex w-full  items-center justify-center h-40">
          <Icons.Spinner className=" h-6 w-6 animate-spin text-white" />
        </div>
      ) : (
        <>
          <div className="flex gap-4 mb-8">
            <Card
              description="Total usinas"
              title={resume.totalStations}
              quantity={11.01}
              critical={0}
            />
            <Card
              description="Potência instalada"
              title={resume.totalCapacity}
              measure="MWp"
              critical={0}
            />
            <Card
              description="Em operação crítica"
              title={resume.criticalOperation}
              measure="usinas"
              critical={resume.criticalOperation}
            />
            <Card
              description="Abaixo do esperado"
              title={resume.belowExpected}
              measure="usinas"
              critical={resume.belowExpected}
            />
          </div>
          <div className="w-[250px] mb-2">
            <Input
              id="filter"
              placeholder="Filtrar usinas..."
              type="text"
              autoCapitalize="none"
              autoCorrect="off"
              className="bg-bgPrimaryDark"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <div className="rounded-md border mb-2">
            <Table className="bg-bgPrimaryDark rounded-md">
              <TableHeader>
                <TableRow>
                  <TableHead>Nome</TableHead>
                  <TableHead>kWp</TableHead>
                  <TableHead>Operação</TableHead>
                  <TableHead>Desempenho</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Localização</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {userStations ? (
                  userStations.map((powerPlant: StationDTO) => {
                    return (
                      <TableRow
                        key={powerPlant.plantCode}
                        className="cursor-pointer"
                        onClick={() =>
                          handleStationData(powerPlant.enterpriseId)
                        }
                      >
                        <TableCell>{powerPlant.plantName}</TableCell>
                        <TableCell>{powerPlant.capacity}</TableCell>
                        <TableCell>
                          <AlarmIndicator alarm={powerPlant.alarm} />
                        </TableCell>
                        <TableCell>{powerPlant.performance ?? '-'}</TableCell>
                        <TableCell>{powerPlant.performance ?? '-'}</TableCell>
                        <TableCell>{powerPlant.plantAddress}</TableCell>
                        <TableCell>
                          <IoEllipsisVerticalSharp />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} className="text-center">
                      Nenhum dado encontrado
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="px-2 flex">
            <Pagination
              currentPage={page}
              perPage={perPage}
              totalPages={totalPages}
              onPageChange={setPage}
            />
          </div>
        </>
      )}
    </HeaderAndSidebar>
  );
}
