import { Icons } from '../icons';

interface DivisionProps {
  title: number;
  description: string;
  measure?: string;
  quantity?: number;
  critical: number;
}

export function Card({
  title,
  description,
  measure,
  quantity,
  critical,
}: DivisionProps) {
  return (
    <div className="flex flex-col w-[296px]  border-solid border-[1px] border-border bg-bgCardsBlack px-6 py-6 gap-4 rounded-xl">
      {critical > 0 ? (
        <h4 className="font-semibold text-sm text-textError">{description}</h4>
      ) : (
        <h4 className="font-semibold text-sm text-white">{description}</h4>
      )}

      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-baseline">
          <h1 className="font-light font- text-[32px] text-white">{title}</h1>
          <p className="font-normal text-white">{measure}</p>
        </div>
        <p className="flex font-normal text-white items-center">
          {quantity && '+'}
          {quantity}
          {quantity && <Icons.ArrowRise className="ml-1 h-7 w-12.5" />}
        </p>
      </div>
    </div>
  );
}
