import { Toaster } from './components/ui/toaster';
import { AuthProvider } from './contexts/AuthContext';
import { CredentialProvider } from './contexts/CredentialContext';
import { EnterpriseProvider } from './contexts/EnterpriseContext';
import { StationProvider } from './contexts/StationsContext';
import AppRoutes from './routes';

function App() {
  return (
    <AuthProvider>
      <EnterpriseProvider>
        <CredentialProvider>
          <StationProvider>
            <Toaster />
            <AppRoutes />
          </StationProvider>
        </CredentialProvider>
      </EnterpriseProvider>
    </AuthProvider>
  );
}

export default App;
