import { ReactNode } from 'react';
import { Button } from '../ui/button';
import { RxCaretRight } from 'react-icons/rx';
import { Icons } from '../icons';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';

type ModalProps = {
  isOpen: boolean;
  openChange: (open: boolean) => void;
  children: ReactNode;
  title?: string;
  subtitle?: string;
  footer?: boolean;
  isIcon?: boolean;
  isDisabled?: boolean;
  loading?: boolean;
  onSubmit: () => void;
  buttonTitle?: string;
};

export default function Modal({
  isOpen,
  openChange,
  children,
  title,
  subtitle,
  isIcon = false,
  loading = false,
  buttonTitle,
  footer = true,
  isDisabled = false,
  onSubmit,
}: ModalProps) {
  const onContinue = () => {
    onSubmit();
  };

  return (
    <Dialog open={isOpen} onOpenChange={openChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{subtitle}</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col pb-3 max-h-[440px]">{children}</div>
        {footer && (
          <DialogFooter>
            <Button variant="secondary" onClick={() => openChange(false)}>
              Cancelar
            </Button>
            <Button onClick={onContinue} disabled={isDisabled} type="submit">
              {loading && (
                <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
              )}
              {buttonTitle || 'Avançar'}
              {isIcon && <RxCaretRight className="ml-2 h-4 w-4" />}
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
}
