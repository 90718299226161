import React, { useState } from 'react';
import { RxPencil2 } from 'react-icons/rx';
import { Link, useNavigate } from 'react-router-dom';
import { Icons } from '../components/icons';
import { Button, buttonVariants } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { toast } from '../components/ui/use-toast';
import sendResetPasswordEmail from '../firebase/auth/resetPassword';
import { cn } from '../lib/utils';

export default function ForgotPassword() {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = React.useState('');

  async function forgotPassword(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const { result, error } = await sendResetPasswordEmail(email);

      if (error) {
        setIsLoading(false);
        toast({
          variant: 'error',
          title: 'Email não encontrado.',
        });
        return;
      }
      toast({
        variant: 'success',
        title: 'Um link para criação de nova senha foi enviado para seu email.',
      });
      navigation('/');
      return result;
    } catch (err) {
      toast({
        variant: 'error',
        title: 'Email não encontrado.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function goToLogin() {
    navigation('/');
  }

  return (
    <div className="flex justify-between bg-backgroundLogin h-screen">
      <div
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0.70) 15.05%, rgba(0, 0, 0, 0.00) 116.72%), url('fundologin.png') right bottom / cover no-repeat, lightgray 50%`,
        }}
        className="w-1/2 hidden lg:flex flex-col justify-between h-screen"
      >
        <div className="p-10">
          <img src="logo-sweg.svg" width={106} height={36} alt="Sweg logo" />
        </div>
      </div>
      <div className="w-full md:w-1/2 p-4 flex-col">
        <div className="flex items-center justify-end mr-4">
          <p className="text-sm text-textColorSidebar mx-4">
            Ainda não tem conta?
          </p>
          <Link
            to="/cadastrar"
            className={cn(buttonVariants({ variant: 'default' }))}
          >
            <RxPencil2 className="mr-2 h-4 w-4" />
            Cadastre-se
          </Link>
        </div>
        <div className="flex justify-center items-center h-[calc(100%-40px)]">
          <div className=" flex flex-col justify-center space-y-8 w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight text-white">
                Esqueci minha senha
              </h1>
            </div>
            <div>
              <form onSubmit={forgotPassword}>
                <div className="grid ">
                  <div className="grid w-full max-w-sm items-center mb-6">
                    <Label
                      className="text-muted-foreground mb-2"
                      htmlFor="email"
                    >
                      Email cadastrado no Sweg
                    </Label>

                    <Input
                      id="email"
                      placeholder="name@example.com"
                      type="email"
                      autoCapitalize="none"
                      autoComplete="email"
                      autoCorrect="off"
                      disabled={isLoading}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <Button variant="default" disabled={isLoading}>
                    {isLoading && (
                      <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Enviar
                  </Button>
                  <Button
                    type="button"
                    onClick={goToLogin}
                    className="text-sweg-secondary mt-4"
                    variant="link"
                  >
                    Voltar para o login
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
