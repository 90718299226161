import { Icons } from './icons';
import { Button } from './ui/button';

interface PaginationProps {
  totalPages: number;
  perPage?: number;
  currentPage: number;
  onPageChange(currentPage: number): void;
}

const SIBLINGS_COUNT = 1;

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter((page) => page > 0);
}

export default function Pagination({
  totalPages,
  currentPage,
  perPage = 5,
  onPageChange,
}: PaginationProps) {
  const lastPage = Math.ceil(totalPages / perPage);
  const isFirst = currentPage === 1;
  const isLast = currentPage === lastPage;

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - SIBLINGS_COUNT, currentPage - 1)
      : [];

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + SIBLINGS_COUNT, lastPage)
        )
      : [];

  return (
    <div className="w-full flex px-2 items-center justify-center">
      <h1 className="text-sm mr-8 text-white">
        Página {currentPage} de {lastPage}
      </h1>
      <div className="flex gap-2">
        <Button
          disabled={isFirst}
          onClick={() => onPageChange(1)}
          variant="secondary"
        >
          <Icons.DoubleArrowLeft />
        </Button>

        <Button
          disabled={isFirst}
          onClick={() => onPageChange(1)}
          variant="secondary"
        >
          <Icons.SingleArrowLeft />
        </Button>

        {/* {currentPage > 1 + SIBLINGS_COUNT && (
        <>
          <Button disabled={isFirst} onClick={() => onPageChange(1)}>
            1
          </Button>

          {currentPage > 2 + SIBLINGS_COUNT && (
            <Button disabled onClick={() => onPageChange(1)}>
              ...
            </Button>
          )}
        </>
      )} */}

        {previousPages.map((page) => (
          <Button onClick={() => onPageChange(page)} key={page}>
            {page}
          </Button>
        ))}

        {nextPages.map((page) => (
          <Button onClick={() => onPageChange(page)} key={page}>
            {page}
          </Button>
        ))}

        {currentPage + SIBLINGS_COUNT < lastPage && (
          <>
            {currentPage + 1 + SIBLINGS_COUNT < lastPage && (
              <Button disabled>...</Button>
            )}

            <Button onClick={() => onPageChange(lastPage)}>{lastPage}</Button>
          </>
        )}

        <Button
          disabled={isLast}
          onClick={() => onPageChange(lastPage)}
          variant="secondary"
        >
          <Icons.SingleArrowRight />
        </Button>

        <Button
          disabled={isLast}
          onClick={() => onPageChange(lastPage)}
          variant="secondary"
        >
          <Icons.DoubleArrowRight />
        </Button>
      </div>
    </div>
  );
}
