import * as React from 'react';
import { useEffect } from 'react';
import { StepOne } from '../components/FirstAccess/stepOne';
import { StepThree } from '../components/FirstAccess/stepThree';
import { StepTwo } from '../components/FirstAccess/stepTwo';
import HeaderAndSidebar from '../components/HeaderSidebar';
import { useCredentials } from '../contexts/CredentialContext';
import { useEnterprises } from '../contexts/EnterpriseContext';
import { useStations } from '../contexts/StationsContext';
import useSize from '../hooks/useSize';

export default function FirstAccess() {
  const [width] = useSize();
  const { userEnterprises } = useEnterprises();
  const { stations } = useStations();
  const { credentials } = useCredentials();
  const [step, setStep] = React.useState('stepOne');

  const isDesktop = width > 1280;

  const handleChangeStepTwo = () => {
    setStep('stepTwo');
  };

  const handleChangeStepThree = () => {
    setStep('stepThree');
  };
  useEffect(() => {
    console.log('first on');
  }, []);
  useEffect(() => {
    if (userEnterprises.length > 0 && credentials.length === 0) {
      setStep('stepTwo');
    } else if (
      userEnterprises.length > 0 &&
      credentials &&
      stations.length === 0
    ) {
      setStep('stepThree');
    }
  }, [userEnterprises, stations, credentials]);

  return (
    <HeaderAndSidebar>
      <div className="flex flex-col w-full items-center justify-center md:flex-row px-10 ">
        <div className="flex flex-col mr-8 ">
          <div className="flex items-center">
            <h1 className=" text-lightGray mr-[18px] text-4xl font-bold">
              Bem-vindo à Sweg
            </h1>
            <div className="h-[3px] w-[220px] bg-gradient-to-r from-cyan-500 to-blue-500"></div>
          </div>
          <p className="text-sm leading-7 text-lightGray">
            Para iniciar siga os passos abaixo. Vamos ajudar você a deixar tudo
            pronto!
          </p>
        </div>
        <img
          src="/solarEnergy.png"
          width={isDesktop ? 387 : 194}
          height={isDesktop ? 281 : 140}
          alt="Imagem com duas placas solares"
        />
      </div>

      {step === 'stepOne' && <StepOne onChangeStep={handleChangeStepTwo} />}
      {step === 'stepTwo' && <StepTwo onChangeStep={handleChangeStepThree} />}
      {step === 'stepThree' && <StepThree />}

      {/* <StepThree /> */}
    </HeaderAndSidebar>
  );
}
