interface StationCardProps {
  description: string;
  children: React.ReactNode;
}

export function StationCard({ description, children }: StationCardProps) {
  return (
    <div className="flex flex-col w-full  border-solid border-[1px] border-border bg-bgCardsBlack px-6 py-4 gap-4 rounded-xl">
      <h4 className="font-semibold text-md text-white">{description}</h4>

      {children}
    </div>
  );
}
