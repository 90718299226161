import { useToast } from '../ui/use-toast';
import { Input } from '../ui/input';
import { Icons } from '../icons';
import api from '../../services/api';
import Modal from '../Modal';
import { useNavigate } from 'react-router-dom';
import { CiPlug1 } from 'react-icons/ci';
import { useState } from 'react';
import { useCredentials } from '../../contexts/CredentialContext';
import { StationDTO } from '../../dtos';
import { key } from '../../config/key';

export function StepThree() {
  const navigation = useNavigate();
  const { toast } = useToast();
  const [openModalAddUsina, setOpenModalAddUsina] = useState(false);
  const [openModalStation, setOpenModalStation] = useState(false);
  const [stationsSelected, setStationsSelected] = useState([] as StationDTO[]);
  const { currentCredentials } = useCredentials();
  const [loading, setLoading] = useState(true);
  const [credentialStorage, setCredentialStorage] = useState({} as any);

  const [stationList, setStationList] = useState([]);

  async function getStations() {
    setLoading(true);
    try {
      let storageData = currentCredentials;
      if (!storageData) {
        const getCredentialStorage = localStorage.getItem(key.credentials);

        if (getCredentialStorage) {
          storageData = JSON.parse(getCredentialStorage);
          setCredentialStorage(storageData);
        }

        const response = await api.get(
          `/credentials/stations/enterprise/${credentialStorage.enterpriseId}/type/${credentialStorage.type}`
        );

        return setStationList(response.data.data.list);
      } else {
        const response = await api.get(
          `/credentials/stations/enterprise/${currentCredentials.enterpriseId}/type/${currentCredentials.type}`
        );
        return setStationList(response.data.data.list);
      }
    } catch (err: any) {
      return toast({
        variant: 'error',
        duration: 3000,
        title: err?.message || 'Não foi possível acessar os dados.',
      });
    } finally {
      setLoading(false);
    }
  }

  async function postStations() {
    try {
      const stationsToSend = stationsSelected.map((station) => {
        return {
          enterpriseId:
            currentCredentials.enterpriseId ?? credentialStorage.enterpriseId,
          type: currentCredentials.type ?? credentialStorage.type,
          capacity: station.capacity,
          contactMethod: station.contactMethod,
          contactPerson: station.contactPerson,
          gridConnectionDate: station.gridConnectionDate,
          latitude: station.latitude,
          longitude: station.longitude,
          plantAddress: station.plantAddress,
          plantCode: station.plantCode,
          plantName: station.plantName,
        };
      });
      const response = await api.post('/stations', stationsToSend);

      toast({
        variant: 'success',
        duration: 3000,
        title: 'Credencial registrada com sucesso.',
      });

      localStorage.setItem(key.stations, JSON.stringify(stationsToSend));

      setOpenModalStation(false);
      navigation('/dashboard');
      return response;
    } catch (err: any) {
      return toast({
        variant: 'error',
        duration: 3000,
        title:
          err?.response?.data?.message ||
          'Não foi possível registrar a credencial.',
      });
    } finally {
    }
  }

  const handleSelectStation = (station: StationDTO) => {
    const index = stationsSelected.findIndex(
      (selectedStation) => selectedStation.plantCode === station.plantCode
    );
    let newSelectedStations = [...stationsSelected];

    if (index > -1) {
      newSelectedStations.splice(index, 1);
    } else {
      newSelectedStations.push(station);
    }
    setStationsSelected(newSelectedStations);
  };

  return (
    <div className="flex mt-8 items-center justify-center">
      <Modal
        isOpen={openModalAddUsina}
        openChange={setOpenModalAddUsina}
        onSubmit={() => setOpenModalStation(true)}
        title="Adicionar usina"
        subtitle="Como deseja adiiconar a nova usina?"
        isIcon
        footer={false}
      >
        <div className="flex gap-6">
          <div
            className="flex flex-col bg-white p-4 rounded-md w-[195px] cursor-pointer"
            onClick={() => {
              getStations();
              setOpenModalStation(true);
            }}
          >
            <CiPlug1 className="h-5 w-5 text-backgroundPrimary mb-2" />
            <h1 className="text-backgroundPrimary font-semibold">
              Importar dados do integrador
            </h1>
          </div>
          {/* <div className="flex flex-col bg-white p-4 rounded-md w-[195px] cursor-pointer">
            <RxPencil2 className="h-5 w-5 text-backgroundPrimary mb-2" />
            <h1 className="text-backgroundPrimary font-semibold">
              Importar dados do integrador
            </h1>
          </div> */}
        </div>
      </Modal>
      <Modal
        isOpen={openModalStation}
        openChange={setOpenModalStation}
        onSubmit={() => {
          postStations();
        }}
        title="Selecione a [operadora]"
        buttonTitle="Avançar"
        isIcon
      >
        <Input
          id="email"
          placeholder="Buscar"
          type="text"
          autoCapitalize="none"
          autoComplete="email"
          autoCorrect="off"
        />

        {loading ? (
          <div className="flex w-full  items-center justify-center h-40">
            <Icons.Spinner className=" h-6 w-6 animate-spin text-white" />
          </div>
        ) : (
          <div className="flex flex-col my-4 max-h-[280px] overflow-auto ">
            {stationList.map((station: any) => {
              return (
                <div
                  className="flex gap-2 py-3 border-solid border-b-[1px] border-border cursor-pointer "
                  key={station?.id}
                  onClick={() => handleSelectStation(station)}
                >
                  {/* <Avatar className="w-[24px] h-[24px] ">
                  <AvatarImage
                    src={station?.plantName}
                    width={24}
                    height={24}
                  />
                </Avatar> */}
                  <p className="text-white">{station?.plantName}</p>
                  {stationsSelected.some(
                    (selectedStation) =>
                      selectedStation.plantCode === station.plantCode
                  ) && <Icons.CheckCircle className="h-6 w-6" />}
                </div>
              );
            })}
          </div>
        )}
      </Modal>
      <div className="w-[314px] p-7 bg-bgSecondary rounded-s-2xl h-[196px] border-[1px] border-mediumGray border-solid flex flex-col justify-between">
        <div className="flex  justify-between">
          <p className="uppercase text-xs text-disabledGray">Passo 1</p>
          <Icons.BuildingDisabled className="h-8 w-8" />
        </div>
        <div className="w-full flex items-center justify-center">
          <Icons.CheckCircle className="h-10 w-10" />
        </div>
        <h1 className="text-disabledGray text-center">Cadastrar empresa</h1>
      </div>
      <div className="w-[314px] p-7 bg-bgSecondary border-y-[1px] border-mediumGray border-solid h-[196px] flex flex-col justify-between">
        <div className="flex justify-between">
          <p className="uppercase text-xs text-disabledGray">Passo 2</p>
          <Icons.PlugDisabled className="h-8 w-8" />
        </div>
        <div className="w-full flex items-center justify-center">
          <Icons.CheckCircle className="h-10 w-10" />
        </div>
        <h1 className="text-disabledGray text-center">Integrar inversores</h1>
      </div>
      <div className="w-[362px] p-7 bg-bgSecondary border-[1px] border-mediumGray border-solid h-[220px] flex flex-col justify-between rounded-e-2xl shadow-shadowCards z-10 relative">
        <div className="flex justify-between">
          <p className="uppercase text-xs text-textColorSidebar">Passo 3</p>
          <Icons.Zap className="h-8 w-8" />
        </div>
        <h1 className="text-2xl font-semibold text-lightGray w-[136px]">
          Adicionar usinas
        </h1>
        <div
          className="w-[48px] h-[48px] rounded-full absolute bottom-[-24px] right-[28px] bg-gradient-to-b from-lightBlueGradient from-7% to-yellowGradient to-86% flex items-center cursor-pointer justify-center"
          onClick={() => setOpenModalAddUsina(!openModalAddUsina)}
        >
          <Icons.CareRight className="h-8 w-8 " />
        </div>
      </div>
    </div>
  );
}
