import { AlarmState } from '../enum';

interface AlarmProps {
  alarm: AlarmState;
}

const getAlarmColor = (alarm: AlarmState) => {
  switch (alarm) {
    case AlarmState.Normal:
      return 'bg-bgGreen';
    case AlarmState.SemGeracao:
      return 'bg-bgRed';
    case AlarmState.SemComunicacao:
      return 'bg-yellow';
    case AlarmState.Offline:
      return 'bg-transparent border-[1px] border-white';
    default:
      return 'bg-transparent';
  }
};

export default function AlarmIndicator({ alarm }: AlarmProps) {
  const alarmColorClass = getAlarmColor(alarm);

  return (
    <div
      className={`h-2 w-2 rounded-full ${alarmColorClass}`}
      title={alarm}
    ></div>
  );
}
