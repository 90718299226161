import { createContext, useContext, useEffect, useState } from 'react';
import { key } from '../config/key';
import { StationDTO } from '../dtos';
import api from '../services/api';
import { useEnterprises } from './EnterpriseContext';

type StationsContextData = {
  stations: StationDTO[];
  stationsLoading?: boolean;
};

const StationsContext = createContext({} as StationsContextData);

const StationProvider = ({ children }: { children: React.ReactNode }) => {
  const [stationsLoading, setStationsLoading] = useState(true);
  const [stations, setStations] = useState<StationDTO[]>([] as StationDTO[]);
  const { currentEnterprise } = useEnterprises();
  const getStations = async () => {
    setStationsLoading(true);

    try {
      const stationsStorage = localStorage.getItem(key.stations);

      if (stationsStorage) {
        setStations(JSON.parse(stationsStorage));
      } else {
        const responseApi = await api.get(
          `/stations/all/${currentEnterprise.enterpriseId}`
        );

        if (responseApi) {
          setStations(responseApi.data);
          localStorage.setItem(key.stations, JSON.stringify(responseApi.data));
        }
      }
      return;
    } catch (error) {
      console.log(error);
    } finally {
      setStationsLoading(false);
    }
  };

  useEffect(() => {
    if (currentEnterprise?.enterpriseId) {
      getStations();
    }
  }, [currentEnterprise?.enterpriseId]);

  return (
    <StationsContext.Provider value={{ stations, stationsLoading }}>
      {children}
    </StationsContext.Provider>
  );
};

function useStations(): StationsContextData {
  const context = useContext(StationsContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { StationProvider, useStations };
