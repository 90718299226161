import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { key } from '../config/key';
import { UserDTO } from '../dtos';
import signInEmail from '../firebase/auth/singnin-email';
import api from '../services/api';

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  signInContext: (
    credentials: SignInCredentials
  ) => Promise<boolean | undefined>;
  signOutContext: () => void;
  user: UserDTO;
  isAuthenticated: boolean;
  authLoading?: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authLoading, setAuthLoading] = useState(true);

  const [user, setUser] = useState<UserDTO>(() => {
    const token = localStorage.getItem(key.token);
    const userStorage = localStorage.getItem(key.user);

    if (token && userStorage) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return JSON.parse(userStorage);
    }

    return {} as UserDTO;
  });

  const isAuthenticated = !!user?.id;

  const signOutContext = useCallback(async () => {
    localStorage.removeItem(key.refreshToken);
    localStorage.removeItem(key.token);
    localStorage.removeItem(key.userEnterprises);
    localStorage.removeItem(key.refreshToken);
    localStorage.removeItem(key.stations);
    localStorage.removeItem(key.credentials);

    setUser({} as UserDTO);
  }, [setUser]);

  useEffect(() => {
    api.registerInterceptTokenManager(signOutContext);
  }, [signOutContext]);

  const signInContext = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      setAuthLoading(true);

      try {
        const { result, error } = await signInEmail(email, password);
        if (error) {
          return false;
        }

        if (result) {
          const user = result.user;
          const token = await user.getIdToken();
          const currentUser = {
            id: user.uid,
            name: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            created_at: new Date(),
            updated_at: new Date(),
            accessToken: token,
          };

          localStorage.setItem(key.refreshToken, user.refreshToken);
          localStorage.setItem(key.token, token);
          localStorage.setItem(key.user, JSON.stringify(currentUser));

          api.defaults.headers.authorization = `Bearer ${token}`;

          setUser(currentUser);
          return true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAuthLoading(false);
      }
    },
    []
  );

  return (
    <AuthContext.Provider
      value={{
        signInContext,
        signOutContext,
        isAuthenticated,
        user,
        authLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
