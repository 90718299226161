import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Icons } from '../components/icons';
import { Button, buttonVariants } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { toast } from '../components/ui/use-toast';
import { cn } from '../lib/utils';
import api from '../services/api';

export default function Signup() {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    password: '',
    phoneNumber: '',
  });

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.post('/users', values);
      await response.data;
      toast({
        variant: 'success',
        duration: 3000,
        title: 'Empresa registrada com sucesso.',
      });

      navigation('/');
    } catch (err: any) {
      toast({
        variant: 'error',
        duration: 3000,
        title: Array.isArray(err.message)
          ? err?.message.join(', ')
          : err.message || 'Erro ao criar o usuário',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex justify-between bg-backgroundLogin h-screen">
      <div
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0.70) 15.05%, rgba(0, 0, 0, 0.00) 116.72%), url('fundo.png') right bottom / cover no-repeat, lightgray 50%`,
        }}
        className="w-1/2 hidden lg:flex flex-col justify-between h-screen"
      >
        <div className="p-10">
          <img src="logo-sweg.svg" width={106} height={36} alt="Sweg logo" />
        </div>
      </div>
      <div className="w-full md:w-1/2 p-4 flex-col">
        <div className="flex items-center justify-end mr-4">
          <p className="text-sm text-textColorSidebar mx-4">Já possui conta?</p>
          <Link to="/" className={cn(buttonVariants({ variant: 'default' }))}>
            <Icons.Login className="mr-2 h-4 w-4" />
            Entrar
          </Link>
        </div>
        <div className="flex justify-center items-center h-[calc(100%-40px)]">
          <div className=" flex flex-col justify-center space-y-8 w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight text-white">
                Cadastre-se na Sweg
              </h1>
            </div>
            <div>
              {/* <Button
        onClick={onGoogleSignIn}
        variant="default"
        type="button"
        disabled={isLoading}
      >
        {isLoading ? (
          <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <FcGoogle className="mr-2 h-4 w-4" />
        )}
        Cadastrar com Google
      </Button>
      <div className="relative my-4">
        <div className="absolute inset-0 flex items-center ">
          <span className="w-full border-t border-border" />
        </div>
        <div className="relative flex justify-center text-xs uppercase ">
          <span className="bg-backgroundLogin px-2 text-muted-foreground">
            OU CRIAR CONTA COM EMAIL
          </span>
        </div>
      </div> */}
              <form onSubmit={onSubmit}>
                <div className="grid ">
                  <div className="grid w-full max-w-sm items-center mb-6">
                    <Label
                      className="text-muted-foreground mb-2"
                      htmlFor="email"
                    >
                      Nome
                    </Label>

                    <Input
                      id="name"
                      placeholder="Insira seu nome"
                      type="text"
                      name="name"
                      autoCorrect="off"
                      disabled={isLoading}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="grid w-full max-w-sm items-center mb-6">
                    <Label
                      className="text-muted-foreground mb-2"
                      htmlFor="email"
                    >
                      Email
                    </Label>

                    <Input
                      id="email"
                      placeholder="Seu melhor e-mail"
                      type="email"
                      autoCapitalize="none"
                      autoComplete="email"
                      name="email"
                      autoCorrect="off"
                      disabled={isLoading}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="grid w-full max-w-sm items-center mb-6">
                    <Label
                      className="text-muted-foreground mb-2"
                      htmlFor="email"
                    >
                      Celular
                    </Label>

                    <Input
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="(11) 00000-0000"
                      type="tel"
                      autoCapitalize="none"
                      autoComplete="phoneNumber"
                      autoCorrect="off"
                      disabled={isLoading}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="grid w-full max-w-sm items-center  mb-6">
                    <Label
                      className="text-muted-foreground mb-2"
                      htmlFor="password"
                    >
                      Crie sua senha
                    </Label>

                    <Input
                      id="password"
                      placeholder="8 ou mais caracteres"
                      type="password"
                      name="password"
                      autoCapitalize="none"
                      autoComplete="password"
                      autoCorrect="off"
                      disabled={isLoading}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <Button variant="default" disabled={isLoading}>
                    {isLoading && (
                      <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Criar conta
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
