import { createContext, useContext, useEffect, useState } from 'react';
import { key } from '../config/key';
import { UserEnterpriseDTO } from '../dtos';
import api from '../services/api';
import { useAuth } from './AuthContext';

type EnterprisesContextData = {
  userEnterprises: UserEnterpriseDTO[];
  currentEnterprise: UserEnterpriseDTO;
  updateUserEnterprise: (userEnterprise: UserEnterpriseDTO) => void;
  enterpriseLoading?: boolean;
};

const EnterprisesContext = createContext({} as EnterprisesContextData);

const EnterpriseProvider = ({ children }: { children: React.ReactNode }) => {
  const [enterpriseLoading, setEnterpriseLoading] = useState(true);

  const { user } = useAuth();
  const [userEnterprises, setUserEnterprises] = useState<UserEnterpriseDTO[]>(
    [] as UserEnterpriseDTO[]
  );
  const [currentEnterprise, setCurrentEnterprise] = useState<UserEnterpriseDTO>(
    {} as UserEnterpriseDTO
  );

  const updateUserEnterprise = async (userEnterprise: UserEnterpriseDTO) => {
    setUserEnterprises([userEnterprise]);
    setCurrentEnterprise(userEnterprise);
  };

  const getUserEnterprise = async () => {
    setEnterpriseLoading(true);

    try {
      const userEnterpriseStorage = localStorage.getItem(key.userEnterprises);

      if (userEnterpriseStorage) {
        setUserEnterprises(JSON.parse(userEnterpriseStorage));
        setCurrentEnterprise(JSON.parse(userEnterpriseStorage)[0]);
      } else {
        const responseApi = await api.get(`/user-enterprises/mine`);

        if (responseApi) {
          setUserEnterprises(responseApi.data);
          setCurrentEnterprise(responseApi.data[0]);
          localStorage.setItem(
            key.userEnterprises,
            JSON.stringify(responseApi.data)
          );
        }

        return responseApi.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setEnterpriseLoading(false);
    }
  };

  useEffect(() => {
    if (user?.accessToken) {
      getUserEnterprise();
    }
  }, [user?.accessToken]);

  return (
    <EnterprisesContext.Provider
      value={{
        userEnterprises,
        currentEnterprise,
        enterpriseLoading,
        updateUserEnterprise,
      }}
    >
      {children}
    </EnterprisesContext.Provider>
  );
};

function useEnterprises(): EnterprisesContextData {
  const context = useContext(EnterprisesContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { EnterpriseProvider, useEnterprises };
