import * as React from 'react';
import { cn } from '../../lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, ...props }, ref) => {
    return (
      <>
        <input
          type={type}
          className={cn(
            'flex h-10 w-full text-white rounded-md border border-borderGraySecondary bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-textGray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-borderGraySecondary focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          ref={ref}
          {...props}
        />
        {!!error && <span className="text-xs text-red-500 mt-1">{error}</span>}
      </>
    );
  }
);
Input.displayName = 'Input';

export { Input };
